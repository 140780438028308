import axios from 'axios';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { screen } from './types/enums';
import Profile from './data/Profile';
import State from './data/State';
import User from './data/User';

import Main from './screens/Main';
import Promo from './screens/Promo';
import ScanMetro from './screens/ScanMetro';
import Reg from './screens/Reg';
import Store from './screens/Store';
import Error from './screens/Error';
import ScanChoose from './screens/ScanChoose';
import ScanManual from './screens/ScanManual';
import ScanQR from './screens/ScanQR';
import About from './screens/About';
import Questionnaire from './screens/Questionnaire';
import Products from './screens/Products';
import Product from './screens/Product';
import Favorites from './screens/Favorites';
import Recipe from './screens/Recipe';
import Cart from './screens/Cart';
import Merch from './screens/Merch';
import Order from './screens/Order';
import Club from './screens/Club';
import Statuses from './screens/Statuses';
import Data from './screens/Data';
import Orders from './screens/Orders';
import Movements from './screens/Movements';
import Rules from './screens/Rules';
import FAQ from './screens/FAQ';
import Support from './screens/Support';
import Contest from './screens/Contest';

declare global {
  interface Window {
    Telegram: {
      WebApp: {
        viewportHeight: number;
        viewportStableHeight: number;
        ready: () => void;
        expand: () => void;
        close: () => void;
        themeParams: {
          bg_color: string;
          text_color: string;
          hint_color: string;
          link_color: string;
          button_color: string;
          button_text_color: string;
          secondary_bg_color: string;
        },
        initData: string;
        initDataUnsafe: {
          user: {
            id: number;
            first_name: string;
            last_name: string;
            username: string;
            language_code: string;
            photo_url: string;
          }
        }
        openTelegramLink: (url: string) => void;
      };
    }
    eruda: {
      init: () => void;
    }
  }
}

const eruda = () => {
  if (User.getID() === 771545999) {
    const body = document.querySelector('body');
    const script = document.createElement('script');
    script.setAttribute('src', '//cdn.jsdelivr.net/npm/eruda');
    body.append(script);
    script.onload = () => {
      window.eruda.init();
      setTimeout(() => {

      }, 1000);
    }
  }
}

const setRegScreen = () => {
  State.setGameBack();
  State.setScreen(screen.REG);
}

const setScanScreen = () => {
  State.setGameBack();
  State.setScreen(screen.SCAN_CHOOSE);
}

const setMerchScreen = () => {
  State.setGameBack();
  State.setScreen(screen.STORE);
}

const setContestScreen = () => {
  State.setGameBack();
  State.setScreen(screen.CONTEST);
}

const init = () => {
  const telegram = window.Telegram.WebApp;
  telegram.ready();
  telegram.expand();

  try { User.setInitData(telegram.initData); }
  catch (e) { User.setInitData(''); }
  try { User.setID(telegram.initDataUnsafe.user.id); }
  catch (e) { User.setID(0); }
  try { User.setUsername(telegram.initDataUnsafe.user.username); }
  catch (e) { User.setUsername('username'); }
  try { User.setFirstName(telegram.initDataUnsafe.user.first_name); }
  catch (e) { User.setFirstName('noname'); }
  try { User.setLastName(telegram.initDataUnsafe.user.last_name); }
  catch (e) { User.setLastName(''); }

  axios.post(process.env.REACT_APP_API + '/getData', {
    init_data: User.getInitData(),
    id: User.getID(),
    username: User.getUsername(),
    first_name: User.getFirstName(),
    last_name: User.getLastName()
  }).then(res => {
    if (!res.data.error) {
      User.setAvatar(res.data.data.avatar);
      User.setScores(res.data.data.scores);
      User.setName(res.data.data.name);
      User.setMail(res.data.data.mail);
      User.setPhone(res.data.data.phone);
      User.setBirthday(res.data.data.birthday);
      User.setCity(res.data.data.city);
      res.data.data.merch && Profile.setMerchFavorites(JSON.parse(res.data.data.merch));
      res.data.data.products && Profile.setProductFavorites(JSON.parse(res.data.data.products));
      res.data.data.recipes && Profile.setRecipeFavorites(JSON.parse(res.data.data.recipes));
      res.data.data.cart && Profile.setSavedCart(JSON.parse(res.data.data.cart));
      const params = new URLSearchParams(window.location.search);
      params.get('screen') === 'reg' && setRegScreen();
      params.get('screen') === 'scan' && setScanScreen();
      params.get('screen') === 'merch' && setMerchScreen();
      params.get('screen') === 'contest' && setContestScreen();
    } else {
      State.setError(res.data.error_type);
      State.setScreen(screen.ERROR);
    }
    User.setLoaded();
  }).catch(e => State.setScreen(screen.ERROR));

  // eruda();
}

export default observer(() => {
  useEffect(() => init(), []);
  if (!User.isLoaded()) return;
  return State.getScreen() === screen.MAIN ? <Main /> :
    State.getScreen() === screen.PROMO ? <Promo /> :
    State.getScreen() === screen.SCAN_METRO ? <ScanMetro /> :
    State.getScreen() === screen.REG ? <Reg /> :
    State.getScreen() === screen.STORE ? <Store /> :
    State.getScreen() === screen.ERROR ? <Error /> :
    State.getScreen() === screen.SCAN_CHOOSE ? <ScanChoose /> :
    State.getScreen() === screen.SCAN_MANUAL ? <ScanManual /> :
    State.getScreen() === screen.SCAN_QR ? <ScanQR /> :
    State.getScreen() === screen.ABOUT ? <About /> :
    State.getScreen() === screen.QUESTIONNAIRE ? <Questionnaire /> :
    State.getScreen() === screen.PRODUCTS ? <Products /> :
    State.getScreen() === screen.PRODUCT ? <Product /> :
    State.getScreen() === screen.FAVORITES ? <Favorites /> :
    State.getScreen() === screen.RECIPE ? <Recipe /> :
    State.getScreen() === screen.CART ? <Cart /> :
    State.getScreen() === screen.MERCH ? <Merch /> :
    State.getScreen() === screen.ORDER ? <Order /> :
    State.getScreen() === screen.CLUB ? <Club /> :
    State.getScreen() === screen.STATUSES ? <Statuses /> :
    State.getScreen() === screen.DATA ? <Data /> :
    State.getScreen() === screen.ORDERS ? <Orders /> :
    State.getScreen() === screen.MOVEMENTS ? <Movements /> :
    State.getScreen() === screen.RULES ? <Rules /> :
    State.getScreen() === screen.FAQ ? <FAQ /> :
    State.getScreen() === screen.SUPPORT ? <Support /> :
    State.getScreen() === screen.CONTEST ? <Contest /> :
    null;
});